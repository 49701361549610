export const POPUP_URL = 'https://ecom.wix.com/storefront/cartwidgetPopup';
export const EMPTY_CART_GUID = '00000000-000000-000000-000000000000';
export const CART_ICON_APP_NAME = 'wixstores-cart-icon';

export const cartIconTranslationPath = (baseUrl: string, locale = 'en') =>
  `${baseUrl}assets/locale/cartIcon/cartIcon_${locale}.json`;

export const SPECS = {
  USE_LIGHTBOXES: 'specs.stores.UseLightboxes',
  NoCssInOOIBundle: 'specs.stores.NoCssInOOIBundle',
  CartIconNoCssInOOIBundle: 'specs.stores.CartIconNoCssInOOIBundle',
  CartIconEditorFlowMigrate: 'specs.stores.CartIconEditorFlowMigrate',
  ChangeIconOverflowSvg: 'specs.stores.ChangeIconOverflowSvg',
  NavigateToCartWhenDontShowSideCartOnMobile: 'specs.stores.NavigateToCartWhenDontShowSideCartOnMobile',
  shouldChangeToBiCatalogV2: 'specs.stores.shouldChangeToBiCatalogV2',
  CallGetAppSettingsOnlyForNotPrimaryLanguage: 'specs.stores.CallGetAppSettingsOnlyForNotPrimaryLanguage',
  CallGetAppSettingsForIconsThatNeeded: 'specs.stores.CallGetAppSettingsForIconsThatNeeded',
  CallGetAppSettingsIfExternalIdDefined: 'specs.stores.CallGetAppSettingsIfExternalIdDefined',
  CallGetAppSettingsDirectlyToScala: 'specs.stores.CallGetAppSettingsDirectlyToScala',
  SeparateSideCartCurrentCartService: 'specs.stores.SeparateSideCartCurrentCartService',
};

// eslint-disable-next-line no-shadow
export enum FedopsInteraction {
  ADD_TO_CART = 'velo-add-to-cart',
  ADD_ITEMS_TO_CART = 'velo-add-items-to-cart',
}

export const origin = 'cart-icon';
